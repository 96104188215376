const DOCUMENT_STORAGE_TTL_IN_DAYS = '7d';
const LONG_DOCUMENT_STORAGE_TTL_IN_DAYS = '30d';
const CODE_STORAGE_TTL_IN_HOURS = '1d';
const DOCUMENT_STORAGE_TTL_IN_DAYS_NUM = 7;
const LONG_DOCUMENT_STORAGE_TTL_IN_DAYS_NUM = 30;
const INIT_FREE_AMOUNT = 6;
const INIT_GOOGLE_CREDITS = 100;
const RESET_PASSWORD = 'reset password';
const NO_EXPIRATION = 'no expiration';
const CHUNK_SIZE = 200;
const MAX_PAGE_POOL_SIZE = 20;
const PRIVATE = 'private';
const LINK_ONLY = 'link_only';
const COMMUNITY = 'community';
const DIAGNOSE_FB = 'diagnose';
const CHAT_FB = 'chat';
const GENERAL_FB = 'general';
const CONCERN_FB = 'concern';
const PAGE_SIZE = 10;
const MAX_FINGERPRINT_PER_USER = 5;
const MAX_ACCOUNTS_PER_DEVICE = 1;
const MAX_ACCOUNTS_PER_IP_ADDRESS = 2;
const CONCURRENT_QUERY_REQUESTS = 10;
const FORK = 'fork';
const SAVE = 'save';
const LIKE = 'like';
const EMAIL_ACTIVE_STATUS = 'Active';
const EMAIL_BOUNCED_STATUS = 'Bounced';
const EMAIL_UNSUBSCRIBE_STATUS = 'Unsubscribe';
const EMAIL_NO_OPENER_STATUS = 'No-opener';
const DEFAULT_SYSTEM_PROMPT =
  process.env.DEFAULT_SYSTEM_PROMPT ||
  "You are ChatGPT, a large language model trained by OpenAI. Follow the user's instructions carefully. Format your answer with paragraph and bullet points using markdown.";

const TRANSACTION_TYPE_TRANSLATE_MAP = {
  'weekly refill': 'weekly_refill',
  'refer bonus': 'refer_credits',
  chat: 'chat_used_credits',
  diagnose: 'diagnose_used_credits',
};

const RERANKER_WEIGHT = [
  /*cosine similarity*/ 0.5, /*cross encoding relavance score*/ 0.5,
];

const TOKEN_LIMIT = 12385;

const LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  ja: 'Japanese',
  de: 'German',
  fr: 'French',
  ru: 'Russian',
  ar: 'Arabic',
  hi: 'Hindi',
  bn: 'Bengali',
  ko: 'Korean',
  zh: 'Chinese',
  id: 'Indonesian',
  ms: 'Malay',
  vi: 'Vietnamese',
  tl: 'Filipino',
  km: 'Cambodian',
  pt: 'Portuguese',
  it: 'Italian',
};

const VET_DB_ENDPOINT_IPS = ['144.24.23.85', '152.67.123.187'];

const PUBMED_DB_ENDPOINT_IPS = ['132.226.62.250', '129.146.182.187'];

const HUMAN_DB_ENDPOINT_IPS = ['129.146.56.230', '129.146.129.135'];

const BLOCKED_IPS = [
  '154.18.180.122',
  '194.13.118.4',
  '154.135.239.246',
  // "::1"
];

const BASE_MODEL_CONVERTER = {
  'gpt-4-turbo': process.env.GPT_4_MODEL,
  'gpt-4o': process.env.GPT_4o_MODEL,
  'gpt-4o-mini': process.env.GPT_4o_MINI_MODEL,
  'claude-3-opus': process.env.ANTHROPIC_OPUS_MODEL,
  'claude-3-5-sonnet': process.env.ANTHROPIC_SONNET_MODEL,
  'claude-3-haiku': process.env.ANTHROPIC_HAIKU_MODEL,
};

const BASE_MODEL_ALLOWLIST = Object.keys(BASE_MODEL_CONVERTER);

const PRODUCTS_MAPPING_LIST = {
  [process.env.NODE_ENV === 'production'
    ? process.env.STRIPE_MEDICAL_CHAt_PREMIUM_PRODUCT_ID
    : process.env.STRIPE_MEDICAL_CHAt_PREMIUM_PRODUCT_ID_TEST]: 'premium',
  [process.env.NODE_ENV === 'production'
    ? process.env.STRIPE_MEDICAL_CHAt_PROFESSIONAL_PRODUCT_ID
    : process.env.STRIPE_MEDICAL_CHAt_PROFESSIONAL_PRODUCT_ID_TEST]:
    'professional',
  [process.env.NODE_ENV === 'production'
    ? process.env.STRIPE_MEDICAL_CHAt_EXTRA_MESSAGES_PRODUCT_ID
    : process.env.STRIPE_MEDICAL_CHAt_EXTRA_MESSAGES_PRODUCT_ID_TEST]:
    'extra_messages',
  [process.env.NODE_ENV === 'production'
    ? process.env.STRIPE_MEDICAL_CHAT_AUTO_CHARGE_PRODUCT_ID
    : process.env.STRIPE_MEDICAL_CHAT_AUTO_CHARGE_PRODUCT_ID_TEST]:
    'auto_charge',
};

const BASE_SUBSCRIPTION_LIST = [
  process.env.NODE_ENV === 'production'
    ? process.env.STRIPE_MEDICAL_CHAt_PREMIUM_PRODUCT_ID
    : process.env.STRIPE_MEDICAL_CHAt_PREMIUM_PRODUCT_ID_TEST,

  process.env.NODE_ENV === 'production'
    ? process.env.STRIPE_MEDICAL_CHAt_PROFESSIONAL_PRODUCT_ID
    : process.env.STRIPE_MEDICAL_CHAt_PROFESSIONAL_PRODUCT_ID_TEST,
];

const MEDICAL_CHAT_PLAN_LIMIT = {
  free: {
    message: INIT_FREE_AMOUNT, //messages
    files: false,
    google: false,
    realtime: false,
    gpt4: false,
    anthropic: false,
    side_by_side: false,
    voice_chat: false,
  },
  premium: {
    exempt: true,
    files: true,
    realtime: false,
    gpt4: true,
    google: false,
    anthropic: false,
    side_by_side: false,
    voice_chat: false,
  },
  professional: {
    message: 3000, //messages
    files: true,
    realtime: true,
    gpt4: true,
    anthropic: true,
    google: true,
    side_by_side: true,
    voice_chat: true,
  },
  extra_messages: {
    message: 1000,
  },
  auto_charge: {
    auto_charge: true,
  },
};

const PLANS_PRODUCT_IDS = {
  Professional:
    process.env.NODE_ENV === 'production'
      ? process.env.STRIPE_MEDICAL_CHAt_PROFESSIONAL_PRODUCT_ID
      : process.env.STRIPE_MEDICAL_CHAt_PROFESSIONAL_PRODUCT_ID_TEST,
  Premium:
    process.env.NODE_ENV === 'production'
      ? process.env.STRIPE_MEDICAL_CHAt_PREMIUM_PRODUCT_ID
      : process.env.STRIPE_MEDICAL_CHAt_PREMIUM_PRODUCT_ID_TEST,
  'Extra Messages':
    process.env.NODE_ENV === 'production'
      ? process.env.STRIPE_MEDICAL_CHAt_EXTRA_MESSAGES_PRODUCT_ID
      : process.env.STRIPE_MEDICAL_CHAt_EXTRA_MESSAGES_PRODUCT_ID_TEST,
  'Auto Charge':
    process.env.NODE_ENV === 'production'
      ? process.env.STRIPE_MEDICAL_CHAT_AUTO_CHARGE_PRODUCT_ID
      : process.env.STRIPE_MEDICAL_CHAT_AUTO_CHARGE_PRODUCT_ID_TEST,
};

const MULTIPLIER_TO_POINTS = {
  'medical-chat': 2,
  'ai-diagnose': 3,
  'google-search': 2,
};

const BASE_MODEL_TO_POINTS = {
  'gpt-4o': 0.75,
  'gpt-4o-mini': 0.5,
  'gpt-4-turbo': 1,
  'claude-3-5-sonnet': 1,
  'claude-3-haiku': 0.5,
  'claude-3-opus': 1.5,
};

const MAX_LOOKBACK_COUNT = 6;

const DEFAULT_IMAGE_DESCRIPTION_PROMPT = `Generate brief descriptions of the image, capturing symptoms, patient demographics (age, gender if visible), any text present, and other relevant details. Keep descriptions clear and concise.`;

module.exports = {
  TOKEN_LIMIT,
  MULTIPLIER_TO_POINTS,
  BASE_MODEL_ALLOWLIST,
  BASE_MODEL_TO_POINTS,
  PLANS_PRODUCT_IDS,
  MAX_LOOKBACK_COUNT,
  DEFAULT_SYSTEM_PROMPT,
  CODE_STORAGE_TTL_IN_HOURS,
  DOCUMENT_STORAGE_TTL_IN_DAYS,
  DOCUMENT_STORAGE_TTL_IN_DAYS_NUM,
  LONG_DOCUMENT_STORAGE_TTL_IN_DAYS,
  LONG_DOCUMENT_STORAGE_TTL_IN_DAYS_NUM,
  INIT_FREE_AMOUNT,
  MEDICAL_CHAT_PLAN_LIMIT,
  PRODUCTS_MAPPING_LIST,
  BASE_SUBSCRIPTION_LIST,
  TRANSACTION_TYPE_TRANSLATE_MAP,
  RESET_PASSWORD,
  NO_EXPIRATION,
  CHUNK_SIZE,
  PRIVATE,
  LINK_ONLY,
  COMMUNITY,
  DIAGNOSE_FB,
  CHAT_FB,
  GENERAL_FB,
  CONCERN_FB,
  PAGE_SIZE,
  FORK,
  SAVE,
  LIKE,
  LANGUAGES,
  VET_DB_ENDPOINT_IPS,
  PUBMED_DB_ENDPOINT_IPS,
  BLOCKED_IPS,
  MAX_FINGERPRINT_PER_USER,
  EMAIL_ACTIVE_STATUS,
  DEFAULT_IMAGE_DESCRIPTION_PROMPT,
  EMAIL_BOUNCED_STATUS,
  EMAIL_UNSUBSCRIBE_STATUS,
  EMAIL_NO_OPENER_STATUS,
  MAX_ACCOUNTS_PER_DEVICE,
  CONCURRENT_QUERY_REQUESTS,
  MAX_ACCOUNTS_PER_IP_ADDRESS,
  INIT_GOOGLE_CREDITS,
  MAX_PAGE_POOL_SIZE,
  HUMAN_DB_ENDPOINT_IPS,
  RERANKER_WEIGHT,
  BASE_MODEL_CONVERTER,
};
