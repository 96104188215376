import { INIT_FREE_AMOUNT } from '../../../server/libs/constant';

export const OPENAI_API_HOST =
  process.env.OPENAI_API_HOST || 'https://api.openai.com';

export const DEFAULT_TEMPERATURE = parseFloat(
  process.env.NEXT_PUBLIC_DEFAULT_TEMPERATURE || '0',
);

export const OPENAI_API_TYPE = process.env.OPENAI_API_TYPE || 'openai';

export const OPENAI_API_VERSION =
  process.env.OPENAI_API_VERSION || '2023-03-15-preview';

export const OPENAI_ORGANIZATION = process.env.OPENAI_ORGANIZATION || '';

export const AZURE_DEPLOYMENT_ID = process.env.AZURE_DEPLOYMENT_ID || '';

export const DEFAULT_MODE =
  process.env.NEXT_PUBLIC_DEFAULT_MODE || 'human_medicine';

export const DEFAULT_MODEL =
  process.env.NEXT_PUBLIC_DEFAULT_MODEL || 'medical-chat';

export const HUMAN_MODE = 'human_medicine';

export const VET_MODE = 'veterinary_medicine';

export const MODE = [HUMAN_MODE, VET_MODE];

export const SEARCH_INTERVAL_MS = 500;

export const DEFAULT_SYSTEM_PROMPT =
  "You are a helpful medical assistant. Answer the user's question and provide a detailed explanation for your answer.";

export const USMLE_RESULTS = [
  [
    'Medical Chat USMLE Correctness Check - Test 1.csv',
    'https://github.com/chat-data-llc/medical_chat_performance_evaluation/blob/main/test_datasets/USMLE/Medical%20Chat%20USMLE%20Correctness%20Check%20-%20Test%201.csv',
    'https://docs.google.com/spreadsheets/d/1_1rG8oldXQZAnQltSH4hCJZ2uor_l_7_IBLjB9Lmntg/edit?usp=sharing',
    '183/188(97.3%)',
  ],
  [
    'Medical Chat USMLE Correctness Check - Test 2.csv',
    'https://github.com/chat-data-llc/medical_chat_performance_evaluation/blob/main/test_datasets/USMLE/Medical%20Chat%20USMLE%20Correctness%20Check%20-%20Test%202.csv',
    'https://docs.google.com/spreadsheets/d/1qCXa7HqiLFwK4QZI-SRP4KMX4kK2e_rZfmhnGbD1Rj4/edit?usp=sharing',
    '218/218(100%)',
  ],
  [
    'Medical Chat USMLE Correctness Check - Test 3.csv',
    'https://github.com/chat-data-llc/medical_chat_performance_evaluation/blob/main/test_datasets/USMLE/Medical%20Chat%20USMLE%20Correctness%20Check%20-%20Test%203.csv',
    'https://docs.google.com/spreadsheets/d/1s8kD75G6eBS7afthoo6fplBnaL6KAkLTmNeprVUZYOE/edit?usp=sharing',
    '236/243(97.1%)',
  ],
];

export const MedQA_RESULTS = [
  [
    'MedQA Correctness Check - US.jsonl',
    'https://github.com/chat-data-llc/medical_chat_performance_evaluation/blob/main/test_datasets/MedQA/US/MedQA%20Correctness%20Check%20-%20US.jsonl',
    'https://docs.google.com/spreadsheets/d/1OkT2_KN0BXgRvl5x9gRpIu36yxOd0Ji-Jk2LDSfe2G8/edit?usp=sharing',
    '1245/1273(97.8%)',
  ],
];

export const MODELS = [
  'medical-chat',
  process.env.NEXT_PUBLIC_GPT_4_MODEL,
  process.env.NEXT_PUBLIC_GPT_4o_MODEL,
  process.env.NEXT_PUBLIC_GPT_4o_MINI_MODEL,
  process.env.NEXT_PUBLIC_ANTHROPIC_OPUS_MODEL,
  process.env.NEXT_PUBLIC_ANTHROPIC_SONNET_MODEL,
  process.env.NEXT_PUBLIC_ANTHROPIC_HAIKU_MODEL,
  'google-search',
];

export const TIER1 = [
  'CH',
  'QA',
  'US',
  'AU',
  'DE',
  'SE',
  'DK',
  'SA',
  'FI',
  'CA',
];

export const TIER2 = [
  'GB',
  'AE',
  'NL',
  'SG',
  'NO',
  'JP',
  'BE',
  'KW',
  'KR',
  'AT',
  'OM',
  'IE',
  'FR',
  'IS',
  'IL',
];

export const EXAMPLES = {
  Specialty: [
    'specialty_example_question_1',
    'specialty_example_question_2',
    'specialty_example_question_3',
    'specialty_example_question_4',
  ],

  'Patient Education': [
    'education_example_question_1',
    'education_example_question_2',
    'education_example_question_3',
    'education_example_question_4',
  ],

  'Drugs Information': [
    'drugs_example_question_1',
    'drugs_example_question_2',
    'drugs_example_question_3',
    'drugs_example_question_4',
  ],
  veterinary_medicine: [
    'vet_example_question_1',
    'vet_example_question_2',
    'vet_example_question_3',
    'vet_example_question_4',
    'vet_example_question_5',
    'vet_example_question_6',
    'vet_example_question_7',
    'vet_example_question_8',
    'vet_example_question_9',
    'vet_example_question_10',
    'vet_example_question_11',
    'vet_example_question_12',
  ],
};

export const LIMITATIONS = [
  'limitation_1',
  'limitation_2',
  'limitation_3',
  'limitation_4',
  'limitation_5',
  'limitation_6',
];

export const CHATBOT_VIDEOS = [
  {
    large: '/demos/chatbot_export_import_en.mp4',
    w_1080: '/demos/chatbot_export_import_en-1080w.mp4',
    w_768: '/demos/chatbot_export_import_en-768w.mp4',
    w_480: '/demos/chatbot_export_import_en-480w.mp4',
  },
  {
    large: '/demos/chatbot_followup_pubmed_en.mp4',
    w_1080: '/demos/chatbot_followup_pubmed_en-1080w.mp4',
    w_768: '/demos/chatbot_followup_pubmed_en-768w.mp4',
    w_480: '/demos/chatbot_followup_pubmed_en-480w.mp4',
  },
  {
    large: '/demos/chatbot_prompt_template_en.mp4',
    w_1080: '/demos/chatbot_prompt_template_en-1080w.mp4',
    w_768: '/demos/chatbot_prompt_template_en-768w.mp4',
    w_480: '/demos/chatbot_prompt_template_en-480w.mp4',
  },
  {
    large: '/demos/chatbot_switch_model_en.mp4',
    w_1080: '/demos/chatbot_switch_model_en-1080w.mp4',
    w_768: '/demos/chatbot_switch_model_en-768w.mp4',
    w_480: '/demos/chatbot_switch_model_en-480w.mp4',
  },
  {
    large: '/demos/chatbot_switch_mode_en.mp4',
    w_1080: '/demos/chatbot_switch_mode_en-1080w.mp4',
    w_768: '/demos/chatbot_switch_mode_en-768w.mp4',
    w_480: '/demos/chatbot_switch_mode_en-480w.mp4',
  },
];

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CHATBOT_VIDEOS_MESSAGES = [
  'Effortlessly export and import conversation history',
  'Efficiently query suggested follow-up questions and search PubMed libraries',
  'Create and save custom prompt templates to eliminate manual typing',
  'Seamlessly switch between different LLM models',
  'Caters to both human medicine and veterinary medicine modes',
];

export const DIAGNOSE_VIDEOS_MESSAGES = [
  'Create patient-specific clinic plans, including differential diagnosis, patient education, and medication recommendations',
  'Effortlessly save, edit, and share clinic plans via private links or within the community',
  'Develop your personal clinic plans based on the clinic plans shared within the community',
];

export const DIAGNOSE_VIDEOS = [
  {
    large: '/demos/ai_diagnosis_en.mp4',
    w_1080: '/demos/ai_diagnosis_en-1080w.mp4',
    w_768: '/demos/ai_diagnosis_en-768w.mp4',
    w_480: '/demos/ai_diagnosis_en-480w.mp4',
  },
  {
    large: '/demos/ai_diagnosis_share_en.mp4',
    w_1080: '/demos/ai_diagnosis_share_en-1080w.mp4',
    w_768: '/demos/ai_diagnosis_share_en-768w.mp4',
    w_480: '/demos/ai_diagnosis_share_en-480w.mp4',
  },
  {
    large: '/demos/fork_report_en.mp4',
    w_1080: '/demos/fork_report_en-1080w.mp4',
    w_768: '/demos/fork_report_en-768w.mp4',
    w_480: '/demos/fork_report_en-480w.mp4',
  },
];

export const LOCALES = {
  'en-US': 'locale_en',
  'cn-ZH': 'locale_cn',
};

export const PAYMENT_LIST = ['Stripe'];

export const SAVE_INTERVAL_MS = 2000;

export const PLANS = {
  free: {
    tag: 'free',
    name: 'Free',
    price: 0,
    text: '',
    items: [
      {
        name: 'message_credits',
        value: INIT_FREE_AMOUNT,
      },
      {
        name: 'medical_chat',
        value: true,
      },
      {
        name: 'gpt4o_mini',
        value: true,
      },
      {
        name: 'ai_diagnosis',
        value: true,
      },
    ],
  },
  premium: {
    tag: 'premium',
    name: 'Premium',
    price: 10.99,
    product_id: 'Premium',
    text: 'Everything in Free, plus...',
    items: [
      {
        name: 'message_credits',
        value: 'Unlimited',
        status: 'change',
      },
      {
        name: 'gpt4',
        value: true,
        status: 'new',
      },
      {
        name: 'files',
        value: true,
        status: 'new',
      },
      {
        name: 'incompatible',
        value: true,
      },
    ],
  },
  professional: {
    tag: 'professional',
    name: 'Professional',
    price: 20,
    product_id: 'Professional',
    text: 'Everything in Free, plus...',
    items: [
      {
        name: 'message_credits',
        value: 3000,
        status: 'change',
      },
      {
        name: 'medical_chat',
        value: true,
      },
      {
        name: 'gpt4o_mini',
        value: true,
      },
      {
        name: 'ai_diagnosis',
        value: true,
      },
      {
        name: 'files',
        value: true,
      },
      {
        name: 'realtime_chat',
        value: true,
        status: 'new',
      },
      {
        name: 'gpt4',
        value: true,
        status: 'new',
      },
      {
        name: 'anthropic',
        value: true,
        status: 'new',
      },
      {
        name: 'side_by_side',
        value: true,
        status: 'new',
      },
      {
        name: 'voice_chat',
        value: true,
        status: 'new',
      },
    ],
  },
};

export const EXTRA_PLANS = [
  {
    tag: 'extra_messages',
    name: 'Extra Message Credits',
    price: '$7 per 1000 msg credits',
    unit_price: 7,
    divider: 1000,
    message: ' extra msg credits every month',
    cavet: 'Credits get renewed every month.',
    product_id: 'Extra Messages',
  },
  {
    tag: 'auto_charge',
    name: 'Auto Charge Message Credits',
    price: '$9 per 1000 msg credits',
    message: "Credits don't expire and can be used across months.",
    product_id: 'Auto Charge',
    one_time: true,
  },
];

export const PRICE_FAQS_1 = [
  {
    q: 'What if I subscribed to the previous Premium plan?',
    a: `The previous Premium plan has been deprecated and we will not accept any new subscriptions to it. If you are currently subscribed to it, you will remain on the plan unless you cancel it yourself.`,
  },
  {
    q: 'Can I upgrade my Premium plan to the Professional plan?',
    a: 'Yes, you can upgrade your Premium plan to the Professional plan at any time. We will issue prorated refunds for the remaining days of your Premium subscription.',
  },
  {
    q: 'What is the main difference between the Professional plan and the Premium plan?',
    a: `The Professional plan has more advanced functions, such as the ability to upload files, use real-time chat, and switch between different LLM models. The Premium plan allows you only to chat with text based queries and has access to GPT-4.`,
  },
];

export const PRICE_FAQS_2 = [
  {
    q: 'Why there are credits limit on the Professional plan?',
    a: 'Because the Professional plan has more advanced functions and consumes costs much faster than the Premium plan, we have to limit the number of credits you can use each month.',
  },
  {
    q: `Can I buy additional message credits for the Professional plan?`,
    a: 'Yes, you can buy additional message credits as adds-on on top of the Professional plan at any time when you run out of the message credits.',
  },
  {
    q: 'What is the difference between the Auto Charge and the Extra Message Credits?',
    a: "The Auto Charge plan allows you to charge your message credits automatically when you run out of the message credits to avoid usage interruption. The refilled credits don't expire and can be used across months. The Extra Message Credits plan allows you to buy additional message credits as adds-on on top of the Professional plan, allowing you to have more message credits renew every month.",
  },
];
