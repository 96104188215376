import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useGoogleAds from '@/hooks/useGoogleAds';
import useSessionStorage from '@/hooks/useSessionStorage';
import FaRobot from '@/components/utils/FaRobot';
import FaStethoscope from '@/components/utils/FaStethoscope';
import VerticalVideoList from '@/components/VerticalVideoList/VerticalVideoList';
import BannerSwiper from '@/components/homepage/BannerSwiper';
import { NavHeader } from '@/components/navbar/NavHeader';
import { AuthProvider } from '@/contexts/AuthContext';
import {
  CHATBOT_VIDEOS,
  CHATBOT_VIDEOS_MESSAGES,
  DIAGNOSE_VIDEOS,
  DIAGNOSE_VIDEOS_MESSAGES,
} from '@/libs/app/const';

const BottomModal = dynamic(() => import('@/components/modals/BottomModal'));
const FAQs = dynamic(() => import('@/components/utils/FAQs'));
const Footer = dynamic(() => import('@/components/bottom/Footer'));
const MedQAEvaluationChart = dynamic(() =>
  import('@/components/evaluation/MedQAEvaluationChart'),
);
const EvaluationChart = dynamic(() =>
  import('@/components/evaluation/EvaluationChart'),
);
export default function Home({ currentUser }) {
  const title = 'Medical Chat | Medical AI Assistant';
  const description =
    'Advanced AI for instant medical answers, clinic plans, veterinary treatments, and patient education with accurate, cited sources.';
  const canonical = 'https://medical.chat-data.com';
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [shouldShowReminder, setShouldShowReminder] = useSessionStorage(
    'web-app',
    true,
  );
  const router = useRouter();
  const { trackPurchase } = useGoogleAds();

  useEffect(() => {
    if (
      isMobileSafari &&
      shouldShowReminder &&
      !('standalone' in window.navigator && window.navigator.standalone)
    ) {
      setShowModal(true);
      setShouldShowReminder(false);
    }
  }, [shouldShowReminder, setShouldShowReminder]);

  useEffect(() => {
    const { session_id } = router.query;
    if (session_id) {
      trackPurchase(session_id, 10.0, 'USD');
    }
  }, [router, trackPurchase]);
  const handleCloseModal = () => {
    setShowModal(false);
    setShouldShowReminder(false);
  };

  const getCurrentPlan = (subscriptions) => {
    if (subscriptions.length > 0) {
      return 'Premium';
    }
    return 'Free';
  };

  const currentPlan = useMemo(
    () => (currentUser ? getCurrentPlan(currentUser.subscriptions) : 'Free'),
    [currentUser],
  );
  useEffect(() => {
    if (!currentUser) return;

    const sendUserInfo = () => {
      const iframe = document.querySelector(
        "iframe[src^='https://www.chat-data.com/chatbot-iframe/']",
      );
      if (!iframe) {
        console.error('No iframes found');
        return;
      }

      iframe.contentWindow.postMessage(
        {
          event: 'user-info',
          user: {
            name: currentUser.name,
            email: currentUser.email,
            info: `Current Subscription Plan: ${currentPlan}.`,
          },
        },
        '*',
      );
    };

    const timeoutId = setTimeout(sendUserInfo, 5000);
    return () => clearTimeout(timeoutId);
  }, [currentUser, currentPlan]);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://medical.chat-data.com/demos/chatbot_en.png"
        />
        <meta property="og:image:width" content="1842" />
        <meta property="og:image:height" content="1204" />
        <meta property="og:image:alt" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@truemedicalchat" />
        <meta name="twitter:creator" content="@truemedicalchat" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://medical.chat-data.com/demos/chatbot_en.png"
        />
        <meta name="twitter:image:width" content="1842" />
        <meta property="twitter:image:height" content="1204" />
        <meta name="twitter:image:alt" content={title} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@graph': [
                {
                  '@type': 'BreadcrumbList',
                  '@id': `${canonical}/#breadcrumblist`,
                  itemListElement: [
                    {
                      '@type': 'ListItem',
                      '@id': `${canonical}/#listItem`,
                      position: 1,
                      name: title,
                      item: `${canonical}/`,
                    },
                  ],
                },
                {
                  '@type': 'Organization',
                  '@id': 'https://medical.chat-data.com/#organization',
                  name: 'Medical Chat',
                  url: 'https://medical.chat-data.com/',
                  logo: {
                    '@type': 'ImageObject',
                    url: 'https://medical.chat-data.com/logo.webp',
                    '@id': 'https://medical.chat-data.com/#organizationLogo',
                    width: 360,
                    height: 360,
                  },
                  image: 'https://medical.chat-data.com/logo.webp',
                  sameAs: [
                    'https://www.youtube.com/@medicalchat',
                    'https://www.crunchbase.com/organization/medical-chat',
                  ],
                  description:
                    'Medical Chat is a cutting-edge AI chatbot tailored for healthcare professionals to deliver prompt and precise responses to intricate medical inquiries. With its capability to cite credible professional sources, Medical Chat distinguishes itself by maintaining the integrity and credibility of its information, making it an exceptional assistant in the fast-paced medical field.',
                },
                {
                  '@type': 'WebPage',
                  '@id': `${canonical}/#webpage`,
                  url: canonical,
                  name: title,
                  description,
                  inLanguage: 'en-US',
                  isPartOf: {
                    '@id': 'https://www.chat-data.com/#website',
                  },
                  breadcrumb: {
                    '@id': `${canonical}/#breadcrumblist`,
                  },
                  datePublished: '2015-07-29T13:44:44+00:00',
                  dateModified: '2024-05-01T17:31:00+00:00',
                },
                {
                  '@type': 'WebSite',
                  '@id': 'https://medical.chat-data.com/#website',
                  url: canonical,
                  name: title,
                  inLanguage: 'en-US',
                  publisher: {
                    '@id': 'https://medical.chat-data.com/#organization',
                  },
                },
              ],
            }),
          }}
        ></script>
      </Head>
      <div className={`root`}>
        <AuthProvider user={currentUser}>
          <NavHeader />
          <div>
            <BannerSwiper />
            <div className="feature-wrapper px-3">
              <div id="instant-answer" className="feature-item">
                <div className="container mx-auto px-2">
                  <div className="feature-row row">
                    <VerticalVideoList
                      icon={<FaRobot className="inline-block feature-icon" />}
                      title={t('feature_1_title')}
                      subtitle={t('feature_1_content')}
                      videos={CHATBOT_VIDEOS}
                      messages={CHATBOT_VIDEOS_MESSAGES}
                      className="video-chat-item"
                    />
                  </div>
                </div>
              </div>
              <div id="diagnosis-report" className="feature-item">
                <div className="container mx-auto px-2">
                  <div className="feature-row row">
                    <VerticalVideoList
                      icon={
                        <FaStethoscope className="inline-block feature-icon" />
                      }
                      title={t('feature_2_title')}
                      subtitle={t('feature_2_content')}
                      videos={DIAGNOSE_VIDEOS}
                      messages={DIAGNOSE_VIDEOS_MESSAGES}
                      className="diagnose-video-item"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className="text-center mt-2 md:mt-4 lg:mt-8 text-2xl md:text-3xl lg:text-4xl font-semibold tracking-tight text-gray-900">
              Performance Evaluation
            </p>
            <EvaluationChart />
            <MedQAEvaluationChart />
            <div className="flex gap-4 justify-center mt-6">
              <Link
                href="/evaluate"
                className="block font-semibold text-gray-500 my-auto"
              >
                <span>BA with OpenAI</span>
                <ChevronRightIcon className="w-4 h-4 inline-block" />
              </Link>
            </div>
            <div className="mx-auto container px-2">
              <section className="text-center feature-home mt-3">
                <div className="feature-home-item my-2">
                  <h3 className="text-center lg:text-left my-3 text-lg md:text-xl lg:text-2xl leading-6 font-bold">
                    {t('cited_home_title')}
                  </h3>
                  <p className="text-slate-600 text-sm md:text-base lg:text-lg">
                    {t('cited_home_content')}
                  </p>
                </div>
                <hr className="lg:hidden m-2 border-slate-300/40" />
                <div className="feature-home-item my-2">
                  <h3 className="text-center lg:text-left my-3 text-lg md:text-xl lg:text-2xl leading-6 font-bold">
                    {t('nih_home_title')}
                  </h3>
                  <p className="text-slate-600 text-sm md:text-base lg:text-lg">
                    {t('nih_home_content')}
                  </p>
                </div>
                <hr className="lg:hidden m-2 border-slate-300/40" />
                <div className="feature-home-item my-2">
                  <h3 className="text-center lg:text-left my-3 text-lg md:text-xl lg:text-2xl leading-6 font-bold">
                    {t('veterinary_home_title')}
                  </h3>
                  <p className="text-slate-600 text-sm md:text-base lg:text-lg">
                    {t('veterinary_home_content')}
                  </p>
                </div>
              </section>
            </div>
            <div
              className="mx-auto my-8 max-w-7xl border border-gray-300 rounded-lg flex flex-col items-center p-8 shadow-md"
              style={{
                backgroundImage:
                  'linear-gradient(to right, rgb(238 245 255), rgb(255 215 246) 51%, rgb(238, 243, 255))',
              }}
            >
              <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold">
                Our Medical Chat models are 100% HIPAA compliant
              </h3>
              <p className="my-1 font-medium">
                Employing industry-standard data encryption
              </p>
              <p className="my-1 font-medium">
                No chat history is retained with our Medical Chat models
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
                <a
                  href="/hipaa-openai.pdf"
                  data-tooltip-id="hipaa-openai-link"
                  target="_blank"
                  className="flex items-center px-6 py-3 rounded-lg transition-all duration-300 
                    bg-white/50 hover:bg-white/80 border border-gray-200 hover:border-gray-300 
                    shadow-sm hover:shadow-md group"
                >
                  <span className="font-semibold text-gray-700 group-hover:text-gray-900">
                    HIPAA contract with OpenAI
                  </span>
                  <ChevronRightIcon
                    className="w-4 h-4 ml-2 text-gray-500 group-hover:text-gray-700 
                    transform group-hover:translate-x-1 transition-transform"
                  />
                </a>
                <a
                  href="/hipaa-anthropic.pdf"
                  data-tooltip-id="hipaa-anthropic-link"
                  target="_blank"
                  className="flex items-center px-6 py-3 rounded-lg transition-all duration-300 
                    bg-white/50 hover:bg-white/80 border border-gray-200 hover:border-gray-300 
                    shadow-sm hover:shadow-md group"
                >
                  <span className="font-semibold text-gray-700 group-hover:text-gray-900">
                    HIPAA contract with Anthropic
                  </span>
                  <ChevronRightIcon
                    className="w-4 h-4 ml-2 text-gray-500 group-hover:text-gray-700 
                    transform group-hover:translate-x-1 transition-transform"
                  />
                </a>
              </div>
            </div>
            <div className="flex gap-x-16 justify-center flex-wrap">
              <Image
                alt="GDPR compliance"
                loading="lazy"
                width={100}
                height={100}
                decoding="async"
                src="/gdpr-logo.webp"
              />
              <Image
                alt="SOC compliance"
                loading="lazy"
                width={100}
                height={100}
                decoding="async"
                src="/soc-logo.webp"
              />
            </div>
            <FAQs />

            {showModal && (
              <BottomModal show={showModal} handleClose={handleCloseModal} />
            )}
            <Footer />
          </div>
        </AuthProvider>
      </div>
    </>
  );
}

export async function getServerSideProps(context) {
  const { req } = context;
  const { getCurrentUser } = await import('@/libs/helper');
  const currentUser = getCurrentUser(req.user);
  return {
    props: {
      currentUser,
    },
  };
}
